import { render, staticRenderFns } from "./RegionIndex.vue?vue&type=template&id=d64d9132&scoped=true&"
import script from "./RegionIndex.vue?vue&type=script&lang=js&"
export * from "./RegionIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d64d9132",
  null
  
)

export default component.exports
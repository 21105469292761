import { render, staticRenderFns } from "./EditRegion.vue?vue&type=template&id=77dcc6eb&scoped=true&"
import script from "./EditRegion.vue?vue&type=script&lang=js&"
export * from "./EditRegion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77dcc6eb",
  null
  
)

export default component.exports
const navigators = [

    {
        title: 'Регионы',
        route: 'regions.index',
        icon: 'mdi-map-marker',

    },
    {
        title: 'Районы',
        icon: 'mdi-city',
        route: 'districts.index',
    },
    // {
    //     title: 'МФИ',
    //     icon: 'mdi-home-city',
    //     route: 'mfies.index',
    // },
    {
        title: 'Агротехник тадбирлар',
        icon: 'mdi-home-city',
        route: 'arrangements.index',
    },
    {
        title: 'Экин турлари',
        icon: 'mdi-tree',
        route: 'plant_types.index',
    },
    {
        title: 'Справочник',
        icon: 'mdi-book',
        route: 'helpers.index',
    },
    {
        title: "Технологик карта",
        icon: "mdi-tractor",
        route: "tech_cards.index"
    },
    {
        title: "Агроном картаси",
        icon: "mdi-tractor",
        route: "agronom.index"
    },
    {
        title: "Далалар",
        icon: "mdi-tractor",
        route: "fields.index"
    }

];


export default navigators
import RegionIndex from "@/pages/regions/RegionIndex";



export default [
    {
        path: '/regions',
        component: RegionIndex,
        name: 'regions.index'
    },
]
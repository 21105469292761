import PlantTypeIndex from "@/pages/plant_types/PlantTypeIndex";



export default [
    {
        path: '/plant-types',
        component: PlantTypeIndex,
        name: 'plant_types.index'
    },
]
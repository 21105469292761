// import axios from 'axios'
/* eslint-disable */

const state = {
    helpers: [],
}

const getters = {
    helpers: state => state.helpers,

}

const actions = {
    getHelpers({commit}) {
        axios.get('/spravochnik').then(response => {
            commit('helpers', response.data)
        })
    }
}

const mutations = {
    helpers(state, helpers) {
        state.helpers = helpers
    }

}

export default {
    state,
    getters,
    actions,
    mutations
}
import MfyIndex from "@/pages/mfies/MfyIndex";



export default [
    {
        path: '/mfies',
        component: MfyIndex,
        name: 'mfies.index'
    },
]
import ArrangementIndex from "@/pages/arrangements/ArrangementIndex";



export default [
    {
        path: '/arrangements',
        component: ArrangementIndex,
        name: 'arrangements.index'
    },
]